<template>
  <div
    class="fixed flex items-center justify-center"
    style="
      background: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 9999;
    "
  >
    <ValidationObserver rules="required" v-slot="{ handleSubmit }">
      <Card
        class="p-5 flex flex-col max-w-md"
        style="min-width: 448px; max-height: 750px; overflow-y: auto"
      >
        <div class="p-1 flex">
          <span
            style="font-size: 10px"
            class="font-black flex flex-grow text-romanSilver uppercase"
          >
            Filters
          </span>
          <span class="flex justify-end">
            <Icon
              class-name="text-darkPurple cursor-pointer mx-auto self-center"
              size="xs"
              icon-name="undo"
            />
          </span>
        </div>
        <divider class="flex-grow w-auto" />

        <div v-if="showSearch" class="flex p-1 mt-3">
          <SearchInput
            :placeholder="searchPlaceHolder"
            v-model="handleSearch"
            @input="searchFilter($event)"
            class="search"
            style="width: 100%; margin-top: 0.1rem"
          />
        </div>

        <div
          class="flex flex-col p-2 mt-5 border border-dashed"
          v-for="(data, index) in filterData"
          :key="index"
        >
          <div class="flex mb-2">
            <span
              style="font-size: 10px"
              class="font-black flex flex-grow text-romanSilver"
            >
              <span class="uppercase">Filter By :</span>
              <span
                class="ml-2 -mt-1 text-sm text-flame"
                style="font-weight: 400"
                >{{ data.header }}</span
              >
            </span>
          </div>
          <divider class="flex-grow w-auto" />
          <div class="flex mb-2">
            <CSelect
              :placeholder="`--Select ${data.header}--`"
              :options="data.optionsBox"
              @input="validateSelection($event, data.value)"
              variant="w-full h-10"
              class="h-10 w-full mx-2 my-1"
            />
          </div>
        </div>

        <div class="mt-10">
          <div class="flex flex-grow">
            <Button
              :class="disableBtn && `btn-disabled`"
              :background="appearance.buttonColor || defaultBTNColor"
              :style="disableBtn ? `color: #878E99` : `color: #FFFFFF`"
              width="8rem"
              @click="disableBtn ? '' : handleSubmit(submit)"
            >
              Apply
            </Button>
            <Button
              background-color="none"
              class="ml-4"
              style="color: #321c3b"
              @click="closeModal()"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Card>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ValidationObserver } from "vee-validate";
import SearchInput from "@scelloo/cloudenly-ui/src/components/search-input";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import Button from "@/components/Button";
import Card from "./Card";
import Divider from "./divider";

export default {
  name: "FilterTable",
  components: {
    ValidationObserver,
    Card,
    CSelect,
    Divider,
    Button,
    SearchInput,
  },
  props: {
    filterData: {
      type: Array,
      default: () => [],
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    searchPlaceHolder: {
      type: String,
      default: "First, Last Name or Email",
    }
  },

  data() {
    return {
      queryParams: [],
      disableBtn: true,
      handleSearch: "",
      keysSelected: [],
      queryOptions: [],
    };
  },
  computed: {
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },

  methods: {
    validateSelection(value, key) {
      if (this.keysSelected.includes(key)) {
        const position = this.keysSelected.indexOf(key, 0);

        this.queryParams.splice(position, 1);
        this.queryOptions.splice(position, 1);
      }
      this.queryParams.push(`&${key}=${value}`);
      this.queryOptions.push({ [key]: value });

      this.disableBtn = false;
      this.keysSelected.push(key);
    },

    searchFilter(val) {
      if (val !== "") {
        this.disableBtn = false;
      }
    },

    closeModal() {
      this.$emit("close", true);
    },

    submit() {
      if (this.handleSearch !== "") {
        this.queryParams.push(`&search=${this.handleSearch}`);
      }
      const params = this.queryParams.toString().replaceAll(",", "");
      this.$emit("getParams", params);

      this.$emit("applyFilter", this.queryOptions);
    },
  },
};
</script>

<style>
.btn-disabled {
  background-color: #eeeeee;
  font-size: 16px;
  cursor: disabled;
}
</style>